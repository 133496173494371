import Modal from 'bootstrap/js/dist/modal';
import Cookies from 'js-cookie';

const options = { backdrop: 'true', keyboard: false };
const welcomePopup = new Modal($('#welcome-popup'), options);

$('#welcome-popup').on('hidden.bs.modal', () => {
  Cookies.set('welcomePopupRead', 'true', { expires: 365 });
});

if (Cookies.get('welcomePopupRead') !== 'true') {
  welcomePopup.show();
}

